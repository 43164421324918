import React, { FC, useCallback } from 'react';

import Button from 'common/Button';
import UmbracoImage from 'common/Image/UmbracoImage';
import { navigateToPage } from 'utils/functions';

import { MultiBrandSlideProps } from './models';

import './multiBrandSlide.scss';

const Carousel: FC<MultiBrandSlideProps> = ({
  button,
  copyright,
  image,
  imageAlt,
  logoFirst,
  logoFirstAlt,
  logoSecond,
  logoSecondAlt,
  productImageBig,
  productImageAlt,
  specialImage,
  specialImageAlt,
  titleImage,
  titleImageAlt,
  titleImageWide,
  titleImageWideAlt,
  titleImageHigh,
  titleImageHighAlt,
}) => {
  const clickHandler = useCallback(
    (url: string) => () => {
      navigateToPage(url);
    },
    []
  );

  return (
    <div className="multi-brand-slide">
      <UmbracoImage className="multi-brand-slide__background-image" image={image} alt={imageAlt} />

      <div className="multi-brand-slide__wrapper">
        {titleImage ? (
          <UmbracoImage
            className="multi-brand-slide__title-image"
            image={titleImage}
            alt={titleImageAlt}
            objectFit="contain"
          />
        ) : null}

        {titleImageWide ? (
          <UmbracoImage
            className="multi-brand-slide__title-image-wide"
            image={titleImageWide}
            alt={titleImageWideAlt}
            objectFit="contain"
          />
        ) : null}

        <div className="multi-brand-slide__images">
          {specialImage ? (
            <UmbracoImage
              className="multi-brand-slide__special-image"
              image={specialImage}
              alt={specialImageAlt}
              objectFit="contain"
            />
          ) : null}

          {titleImageHigh ? (
            <UmbracoImage
              className="multi-brand-slide__title-image-high"
              image={titleImageHigh}
              alt={titleImageHighAlt}
              objectFit="contain"
            />
          ) : null}

          {logoFirst && logoSecond ? (
            <div className="multi-brand-slide__logos">
              <UmbracoImage
                className="multi-brand-slide__logo-second"
                image={logoSecond}
                alt={logoSecondAlt}
                objectFit="contain"
              />
              <UmbracoImage
                className="multi-brand-slide__logo-first"
                image={logoFirst}
                alt={logoFirstAlt}
                objectFit="contain"
              />
            </div>
          ) : null}

          {productImageBig ? (
            <UmbracoImage
              className="multi-brand-slide__product-image"
              image={productImageBig}
              alt={productImageAlt}
              objectFit="contain"
            />
          ) : null}
        </div>

        {button?.map((item, i) =>
          item.link[0].target ? (
            <a
              className="btn multi-brand-slide__btn"
              key={`${item.ariaLabel}-${i}`}
              aria-label={item.ariaLabel}
              href={item.link[0].url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {item.text}
            </a>
          ) : (
            <Button
              ariaLabel={item.ariaLabel}
              onClick={clickHandler(item.link[0].url)}
              key={`${item.ariaLabel}-${i}`}
              className="btn multi-brand-slide__btn"
            >
              {item.text}
            </Button>
          )
        )}

        <div className="multi-brand-slide__copyright">
          <p className="multi-brand-slide__copyright-wrapper">{copyright}</p>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
