import React, { FC, useCallback } from 'react';

import UmbracoImage from 'common/Image/UmbracoImage';

import { CampaignBannerSlideProps } from './models';

import './campaignBannerSlide.scss';
import Button from '../../Button';
import { navigateToPage } from '../../../utils/functions';

const CampaignBannerSlide: FC<CampaignBannerSlideProps> = ({
  copyright,
  image,
  imageAlt,
  logoSecond,
  logoSecondAlt,
  titleImageWide,
  titleImageWideAlt,
  titleImageHigh,
  titleImageHighAlt,
  heroImage,
  heroImageAlt,
  button,
}) => {
  const clickHandler = useCallback(
    (url: string) => () => {
      navigateToPage(url);
    },
    []
  );

  return (
    <div className="campaign-banner-slide" data-testid="campaign-banner-slide">
      <UmbracoImage
        className="campaign-banner-slide__background-image"
        image={image}
        alt={imageAlt}
      />

      <div className="campaign-banner-slide__wrapper">
        {logoSecond ? (
          <UmbracoImage
            className="campaign-banner-slide__logo-second"
            image={logoSecond}
            alt={logoSecondAlt}
            objectFit="contain"
          />
        ) : null}

        {titleImageWide ? (
          <UmbracoImage
            className="campaign-banner-slide__title-image-wide"
            image={titleImageWide}
            alt={titleImageWideAlt}
            objectFit="contain"
          />
        ) : null}

        {titleImageHigh ? (
          <UmbracoImage
            className="campaign-banner-slide__title-image-high"
            image={titleImageHigh}
            alt={titleImageHighAlt}
            objectFit="contain"
          />
        ) : null}

        {button?.map((item, i) =>
          item.link[0].target ? (
            <a
              className="btn campaign-banner-slide__btn"
              key={`${item.ariaLabel}-${i}`}
              aria-label={item.ariaLabel}
              href={item.link[0].url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {item.text}
            </a>
          ) : (
            <Button
              ariaLabel={item.ariaLabel}
              onClick={clickHandler(item.link[0].url)}
              key={`${item.ariaLabel}-${i}`}
              className="btn multi-brand-slide__btn"
            >
              {item.text}
            </Button>
          )
        )}
      </div>

      <div className="campaign-banner-slide__copyright">
        <p className="campaign-banner-slide__copyright-wrapper">{copyright}</p>
      </div>

      {heroImage ? (
        <UmbracoImage
          className="campaign-banner-slide__person-image"
          image={heroImage}
          alt={heroImageAlt}
          objectFit="contain"
        />
      ) : null}
    </div>
  );
};

export default CampaignBannerSlide;
