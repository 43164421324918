import React, { FC, useContext } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import MultiBrandSlide from 'gatsby-theme-husky/src/common/Carousel/MultiBrandSlide';
import CampaignBannerSlide from 'gatsby-theme-husky/src/common/Carousel/CampaignBannerSlide';
import ScreenRecognitionContext from 'gatsby-theme-husky/src/utils/context/screenRecognitionContext';
import { CAROUSEL_DEFAULT_SETTINGS } from 'gatsby-theme-husky/src/utils/constants';
import { navigateToPage } from 'gatsby-theme-husky/src/utils/functions';
import UmbracoImage from 'gatsby-theme-husky/src/common/Image/UmbracoImage';
import { CarouselProps } from 'gatsby-theme-husky/src/common/Carousel/models';
import Button from 'gatsby-theme-husky/src/common/Button';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';

import { SLIDE_STYLE } from './constants';

import 'gatsby-theme-husky/src/common/Carousel/carousel.scss';
import './CarouselOverride.scss';

const Carousel: FC<CarouselProps> = ({
  carouselImages,
  className,
  settings = CAROUSEL_DEFAULT_SETTINGS,
  children,
}) => {
  const screenRecognitionContext = useContext(ScreenRecognitionContext);
  const carouselImageItems = screenRecognitionContext.isMobile
    ? carouselImages?.carouselItemsMobile
    : carouselImages?.carouselItemsDesktop;

  return (
    <Slider {...settings} className={className}>
      {children ||
        carouselImageItems.map(
          ({
            image,
            imageAlt,
            text,
            button,
            logoFirst,
            logoFirstAlt,
            ribbonImage,
            logoSecond,
            logoSecondAlt,
            titleImage,
            titleImageAlt,
            titleImageWide,
            titleImageWideAlt,
            titleImageHigh,
            titleImageHighAlt,
            description,
            subDescription,
            title,
            productImage,
            productImageBig,
            productImageAlt,
            copyright,
            specialImage,
            specialImageAlt,
            slideStyle,
            isCashbackStyle,
          }) =>
            slideStyle === SLIDE_STYLE.MULTI_BRAND_SLIDE ? (
              <MultiBrandSlide
                key={image.fallbackUrl}
                button={button}
                copyright={copyright}
                image={image}
                imageAlt={imageAlt}
                logoFirst={logoFirst}
                logoFirstAlt={logoFirstAlt}
                logoSecond={logoSecond}
                logoSecondAlt={logoSecondAlt}
                productImageBig={productImageBig}
                productImageAlt={productImageAlt}
                specialImage={specialImage}
                specialImageAlt={specialImageAlt}
                titleImage={titleImage}
                titleImageAlt={titleImageAlt}
                titleImageWide={titleImageWide}
                titleImageWideAlt={titleImageWideAlt}
                titleImageHigh={titleImageHigh}
                titleImageHighAlt={titleImageHighAlt}
              />
            ) : slideStyle === SLIDE_STYLE.CAMPAIGN_BANNER_SLIDE ? (
              <CampaignBannerSlide
                key={image.fallbackUrl}
                copyright={copyright}
                image={image}
                imageAlt={imageAlt}
                logoSecond={logoSecond}
                logoSecondAlt={logoSecondAlt}
                titleImageWide={titleImageWide}
                titleImageWideAlt={titleImageWideAlt}
                titleImageHigh={titleImageHigh}
                titleImageHighAlt={titleImageHighAlt}
                heroImage={productImage}
                heroImageAlt={productImageAlt}
                button={button}
              />
            ) : (
              <div key={image.fallbackUrl} className="banner-carousel-slider__item-wrapper">
                <UmbracoImage
                  className="banner-carousel-slider__background-image"
                  image={image}
                  alt={imageAlt}
                />
                <div
                  className={classNames('banner-carousel-slider__content', {
                    'banner-carousel-slider__content--flex-start': logoFirst && logoSecond && title,
                    'banner-carousel-slider__content--images-content': isCashbackStyle,
                  })}
                >
                  <div className="content-flex-wrapper">
                    <UmbracoImage
                      className="banner-carousel-slider__ribbon-image"
                      image={ribbonImage}
                      objectFit="contain"
                      alt=""
                    />
                    <div className="content__images-block">
                      {logoFirst && logoSecond ? (
                        <div className="content__logos">
                          <UmbracoImage
                            className="content__logo-first"
                            image={logoFirst}
                            alt={logoFirstAlt}
                            {...(isCashbackStyle && { objectFit: 'contain' })}
                          />
                          <UmbracoImage
                            className="content__logo-second"
                            image={logoSecond}
                            alt={logoSecondAlt}
                            {...(isCashbackStyle && { objectFit: 'contain' })}
                          />
                        </div>
                      ) : null}
                      {titleImage || titleImageHigh ? (
                        <UmbracoImage
                          className="content__title-image"
                          image={titleImageHigh || titleImage}
                          alt={titleImageAlt}
                          {...(isCashbackStyle && { objectFit: 'contain' })}
                        />
                      ) : null}
                    </div>
                    {description && subDescription ? (
                      <div className="content__text">
                        <div className="content__text-description">{description}</div>
                        <div className="content__text-sub-description">{subDescription}</div>
                      </div>
                    ) : null}
                    <DangerouslySetInnerHtml className="banner-carousel-slider__text" html={text} />
                    <div className="content__bottom-block">
                      {(title && productImage) || (isCashbackStyle && productImage) ? (
                        <div className="content__product">
                          <div className="content__product-title">{title}</div>
                          <UmbracoImage
                            className="content__product-image"
                            image={productImage}
                            alt={productImageAlt}
                            {...(isCashbackStyle && { objectFit: 'contain' })}
                          />
                        </div>
                      ) : null}
                      {button?.map((item, i) => (
                        <>
                          {item.link[0].target ? (
                            <a
                              className={classNames('btn banner-carousel-slider__btn', {
                                'original-style': isCashbackStyle,
                              })}
                              key={`${item.ariaLabel}-${i}`}
                              aria-label={item.ariaLabel}
                              href={item.link[0].url}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {item.text}
                            </a>
                          ) : (
                            <Button
                              ariaLabel={item.ariaLabel}
                              onClick={() => navigateToPage(item.link[0].url)}
                              key={`${item.ariaLabel}-${i}`}
                              className={classNames('banner-carousel-slider__btn', {
                                'original-style': isCashbackStyle,
                              })}
                            >
                              {item.text}
                            </Button>
                          )}
                        </>
                      ))}
                    </div>
                  </div>

                  <div className="banner-carousel-slider__copyright">
                    <div className="banner-carousel-slider__copyright-wrapper">{copyright}</div>
                  </div>
                </div>
              </div>
            )
        )}
    </Slider>
  );
};

export default Carousel;
